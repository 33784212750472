<template>
    <div class="overflow-hidden" style="background: url('/assets/images/background.jpeg');margin-top:-155px;background-size: cover;background-repeat: no-repeat;height: 100%;">
        
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-8 col-lg-6 col-xl-5" style='margin-top:100px'>
                    <div class="card overflow-hidden">

                        <div class="card-body pt-0"> 
                            <div class="auth-logo" style="margin-top: 30px; margin-bottom:40px">
                                <center>
                                    <img src="/assets/images/mynursery-logo-rounded.png" style="width: 80%">
                                </center>
                            </div>

                            <div class="p-2">
                                
                                <form class="form-horizontal" @submit.prevent="login">
        
                                    <div class="mb-3">
                                        <label 
                                            for="email" 
                                            class="form-label"
                                            :class="($i18n.locale == 'ar') ? 'hacen_algeria font-size-15' : ''"
                                            :style="($i18n.locale == 'ar') ? '' : 'float:left'">
                                            {{ $t('auth.email_address') }}
                                        </label>
                                        <input 
                                            type="text" 
                                            class="form-control" 
                                            :style="($i18n.locale == 'ar') ? '' : 'text-align:left'"
                                            id="email"
                                            v-model="row.email">
                                    </div>
                
                                    <div class="mb-3">
                                        <label 
                                            for="password"
                                            class="form-label"
                                            :class="($i18n.locale == 'ar') ? 'hacen_algeria font-size-15' : ''"
                                            :style="($i18n.locale == 'ar') ? '' : 'float:left'">
                                            {{ $t('auth.password') }}
                                        </label>
                                        <input 
                                            type="password" 
                                            class="form-control" 
                                            :style="($i18n.locale == 'ar') ? '' : 'text-align:left'"
                                            id="password"
                                            autocomplete="off"
                                            v-model="row.password">
                                    </div>
                                    <div class="clearfix"></div>
                                    <div class="form-check" 
                                        :style="($i18n.locale == 'ar') ? '' : 'direction:ltr;float:left;margin-top:5px'">
                                        <input 
                                            class="form-check-input" 
                                            type="checkbox" 
                                            id="remember-check">
                                        <label 
                                            class="form-check-label" 
                                            for="remember-check"
                                            :class="($i18n.locale == 'ar') ? 'hacen_algeria font-size-15' : ''"
                                            :style="($i18n.locale == 'ar') ? '' : 'margin-left:20px;margin-top:3px'">
                                            {{ $t('auth.remember_me') }}
                                        </label>
                                    </div>
                                    <div class="clearfix"><br/></div>
                                        
                                    <div class="mt-3 d-grid">
                                        <button 
                                            class="btn btn-primary btn-block waves-effect waves-light" 
                                            :disabled="btnLoading"
                                            style="background: #000;border:1px solid #000"
                                            type="submit">
                                            <span v-if="btnLoading">
                                                <span class="spinner-grow spinner-grow-sm mr-1" 
                                                role="status" aria-hidden="true"></span>
                                            </span>
                                            <span 
                                                v-if="!btnLoading"
                                                :class="($i18n.locale == 'ar') ? 'hacen_algeria font-size-16' : ''">
                                                {{ $t('auth.login') }}
                                            </span>
                                        </button>
                                    </div>
                                  
                                </form>
                            </div>
            
                        </div>
                    </div>

                </div>
            </div>
        </div>

            <div class="row">
                <div class="col-sm-4"></div>
                <div class="col-sm-4 text-center">
                    <p :class="($i18n.locale == 'ar') ? 'hacen_algeria font-size-16' : ''">
                        {{ $t('app.copyright') }}
                        <a href="https://gatetechs.com" target="_blank">
                            {{ $t('app.gatetechs') }}
                        </a>
                    </p>
                </div>
                <div class="col-sm-4"></div>
            </div>

        
    </div>
</template>

<script>
import iziToast from 'izitoast';

export default {
    name: 'Login',
    data(){
        return {
            row: {
                email: '',
                password: '',
                keepMeAlive: false,
            },

            btnLoading: false,
        }
    },
    mounted() {},
    methods: {

        // Keep me alive
        keepMeAlive() {
            if(this.keepMeAlive) {
                this.keepMeAlive = false
            } else {
                this.keepMeAlive = true
            }
        },

        // doLogin
        login(){
            this.btnLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
            };
            const options = {
                url: window.baseURL+'/auth/login',
                method: 'POST',
                data: {
                    email: this.row.email,
                    password: this.row.password,
                    keepMeAlive: this.row.keepMeAlive
                }
            }
            this.$axios(options)
            .then(res => {
                this.btnLoading = false;
                this.row.password = '';

                // LocalStorage
                localStorage.setItem('user_id', res.data.data.id);
                localStorage.setItem('encrypt_id', res.data.data.encrypt_id);
                localStorage.setItem('access_token', res.data.data.access_token);
                localStorage.setItem('avatar', res.data.data.avatar);
                localStorage.setItem('username', res.data.data.name);
                localStorage.setItem('lecturer_id', res.data.data.lecturer_id);
                // if(res.data.user.partner) {
                //     localStorage.setItem('partner_id', res.data.user.partner.encrypt_id);
                //     localStorage.setItem('just_partner_id', res.data.user.partner.id);
                //     localStorage.setItem('role', res.data.user.partner.title);
                // } else {
                    localStorage.setItem('role', res.data.data.role);
                //}
                localStorage.setItem('theme_color', 'dark');
                
                // expire at....
                this.$router.push({ name: 'dashboard' });
            })
            .catch(() => {
                this.btnLoading = false;
                this.row.password = '';

                // 401 Unauthorized
                iziToast.error({
                    icon: 'bx bx-angry',
                    title: '',
                    message: 'البريد الإلكتروني او كلمة المرور غير صحيحة'
                });
            });
        },

    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>